import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { ButtonPrimary, ButtonSecondary} from "@medforall/medforall-component-library";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps  } from 'recharts';
import { ChatBubbleLeftEllipsisIcon, CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useAppContext } from '../../Context';
import { addCommentToSupportTicket, getAllTicketsForDateRange, getSupportTicketByID, markTicketAsResolved } from '../../apicalls/sessionService';
import { RemoteSupportTicket, SupportTicketComment } from '../../types/remoteSupportTicket';
import { set } from 'lodash';
import { LoadingSpinner } from '../../components/portalComponents/loadingSpinner';
import { createChat, getGraphProfile, getMyChats, sendMessageInChat } from '../../apicalls/graphService';
import { Label, Select, Textarea } from '../../components/forms/formFields';
import CalendarSelector from '../../components/calendars/CalendarSelector';
import moment from 'moment';
import CommentBlock from '../../components/containers/CommentBlock';
import SuccessDialog from '../../components/dialogs/successDialog';
import { ButtonErrorSecondary } from '../../components/buttons/Buttons';
import { escapeSingleQuotes } from '../../apicalls/announcementService';

// Sample data for testing
const sampleTickets = [
  // Client Technical Support
  { id: 1, category: 'Client Technical Support', subject: 'Camera not working', severity: 'High', clientName: 'John Doe', createdDate: '2024-06-20T14:30:00', status: 'Pending' },
  { id: 2, category: 'Client Technical Support', subject: 'Medication dispenser malfunction', severity: 'High', clientName: 'Emma Wilson', createdDate: '2024-06-21T10:15:00', status: 'Pending' },
  { id: 3, category: 'Client Technical Support', subject: 'Smart home device offline', severity: 'Moderate', clientName: 'Michael Brown', createdDate: '2024-06-22T09:45:00', status: 'Resolved' },
  { id: 4, category: 'Client Technical Support', subject: 'Fall detection sensor issue', severity: 'High', clientName: 'Sarah Johnson', createdDate: '2024-06-23T16:20:00', status: 'Pending' },
  
  // Employee Technical Support
  { id: 5, category: 'Employee Technical Support', subject: 'Can\'t access email', severity: 'Moderate', clientName: 'N/A', createdDate: '2024-06-21T09:15:00', status: 'Pending' },
  { id: 6, category: 'Employee Technical Support', subject: 'VPN connection issues', severity: 'High', clientName: 'N/A', createdDate: '2024-06-22T11:30:00', status: 'Resolved' },
  { id: 7, category: 'Employee Technical Support', subject: 'Printer not responding', severity: 'Low', clientName: 'N/A', createdDate: '2024-06-23T14:45:00', status: 'Pending' },
  { id: 8, category: 'Employee Technical Support', subject: 'Software license expired', severity: 'Moderate', clientName: 'N/A', createdDate: '2024-06-24T10:00:00', status: 'Pending' },
  
  // Client Transportation Request
  { id: 9, category: 'Client Transportation Request', subject: 'Need ride to doctor', severity: 'Low', clientName: 'Jane Smith', createdDate: '2024-06-22T11:00:00', status: 'Resolved' },
  { id: 10, category: 'Client Transportation Request', subject: 'Grocery store trip request', severity: 'Low', clientName: 'Robert Davis', createdDate: '2024-06-23T13:30:00', status: 'Pending' },
  { id: 11, category: 'Client Transportation Request', subject: 'Pharmacy pickup needed', severity: 'Moderate', clientName: 'Linda Wilson', createdDate: '2024-06-24T15:45:00', status: 'Pending' },
  { id: 12, category: 'Client Transportation Request', subject: 'Weekly social club transport', severity: 'Low', clientName: 'Thomas Moore', createdDate: '2024-06-25T09:00:00', status: 'Resolved' },
  
  // Other Client Request
  { id: 13, category: 'Other Client Request', subject: 'Change in meal plan', severity: 'Low', clientName: 'Bob Johnson', createdDate: '2024-06-23T16:45:00', status: 'Pending' },
  { id: 14, category: 'Other Client Request', subject: 'Request for additional cleaning service', severity: 'Low', clientName: 'Grace Lee', createdDate: '2024-06-24T11:20:00', status: 'Resolved' },
  { id: 15, category: 'Other Client Request', subject: 'Need assistance with pet care', severity: 'Moderate', clientName: 'William Turner', createdDate: '2024-06-25T14:10:00', status: 'Pending' },
  { id: 16, category: 'Other Client Request', subject: 'Request for social worker visit', severity: 'Moderate', clientName: 'Olivia Martinez', createdDate: '2024-06-26T10:30:00', status: 'Pending' },
  
  // Client Consultation Request
  { id: 17, category: 'Client Consultation Request', subject: 'Medication review needed', severity: 'Moderate', clientName: 'Alice Brown', createdDate: '2024-06-24T10:30:00', status: 'Pending' },
  { id: 18, category: 'Client Consultation Request', subject: 'Discuss changes in care plan', severity: 'High', clientName: 'David Wilson', createdDate: '2024-06-25T13:15:00', status: 'Resolved' },
  { id: 19, category: 'Client Consultation Request', subject: 'Family meeting request', severity: 'Low', clientName: 'Emily Davis', createdDate: '2024-06-26T11:45:00', status: 'Pending' },
  { id: 20, category: 'Client Consultation Request', subject: 'Mental health check-in', severity: 'High', clientName: 'James Thompson', createdDate: '2024-06-27T09:30:00', status: 'Pending' },
];

// const sampleStats = [
//   { name: 'Client Technical Support', pending: 8, resolved: 15 },
//   { name: 'Employee Technical Support', pending: 3, resolved: 7 },
//   { name: 'Client Transportation Request', pending: 2, resolved: 10 },
//   { name: 'Other Client Request', pending: 5, resolved: 12 },
//   { name: 'Client Consultation Request', pending: 4, resolved: 9 },
// ];

const sampleStats = [
    { name: 'Client Technical Support', pendingLow: 2, pendingModerate: 3, pendingHigh: 3, resolvedLow: 5, resolvedModerate: 6, resolvedHigh: 4 },
    { name: 'Employee Technical Support', pendingLow: 1, pendingModerate: 1, pendingHigh: 1, resolvedLow: 2, resolvedModerate: 3, resolvedHigh: 2 },
    { name: 'Client Transportation Request', pendingLow: 1, pendingModerate: 1, pendingHigh: 0, resolvedLow: 4, resolvedModerate: 4, resolvedHigh: 2 },
    { name: 'Other Client Request', pendingLow: 2, pendingModerate: 2, pendingHigh: 1, resolvedLow: 5, resolvedModerate: 4, resolvedHigh: 3 },
    { name: 'Client Consultation Request', pendingLow: 1, pendingModerate: 2, pendingHigh: 1, resolvedLow: 3, resolvedModerate: 4, resolvedHigh: 2 },
];

export function truncateString(input) {
  const maxLength = 160;
  if (input.length > maxLength) {
      return input.slice(0, maxLength - 3) + '...';
  }
  return input;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

interface CustomTooltipProps extends TooltipProps<number, string> {
    active?: boolean;
    payload?: any[];
    label?: string;
  }

  interface TicketStats {
    name: string;
    pendingLow: number;
    pendingModerate: number;
    pendingHigh: number;
    resolvedLow: number;
    resolvedModerate: number;
    resolvedHigh: number;
  }

const AdminTicketingDashboard = props => {
    const [tickets, setTickets] = useState<RemoteSupportTicket[]>([]);
    const [stats, setStats] = useState(sampleStats);
    const [startDate, setStartDate] = useState(moment().subtract(30, 'days').format('M-DD-YYYY'));
    const [endDate, setEndDate] = useState(moment().add(1, 'days').format('M-DD-YYYY'));
    const [isStatsCollapsed, setIsStatsCollapsed] = useState(false);
    const [expandedTicketId, setExpandedTicketId] = useState(null);

    const [sortedTickets, setSortedTickets] = useState<RemoteSupportTicket[]>([]);

    const [sortedBy, setSortedBy] = useState('createdDate');

    useEffect(() => {
      if(sortedBy === 'createdDate'){
        setSortedTickets(tickets.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()));
      }else if(sortedBy === 'ticketCategory'){
        setSortedTickets(tickets.sort((a, b) => severityValues[b.ticketSeverity] - severityValues[a.ticketSeverity]));
      }

    }, [tickets]);
    

    const [loadingTickets, setLoadingTickets] = useState(false);
  
    const statsRef = useRef(null);

    const appContext = useAppContext();

    useLayoutEffect(() => {
      const getChatInfo = async () => {
        var result = await getMyChats();

        console.log("Chat Info");
        console.log(result);

        // var createdChat = await createChat();

        // console.log("Created Chat");
        // console.log(createdChat);

        // console.log(createdChat.id);

        // var sendTeamsMessage = await sendMessageInChat(createdChat.id);

        // console.log("Sent Teams Message");
        // console.log(sendTeamsMessage);


      }

      //getChatInfo();
    }, []);

    const [currentTabs, setCurrentTabs] = useState([
      { name: 'List', href: '#', current: true },
      { name: 'Statistics', href: '#', current: false }
  ]);

  const [currentActiveTab, setCurrentActiveTab] = useState<string>("List");
    
  const NavigateToTab = (tab) => {
    setCurrentTabs(currentTabs.map((item) => {
        if (item.name === tab.name) {
            return {
                ...item,
                current: true
            }
        } else {
            return {
                ...item,
                current: false
            }
        }
    }));

    setCurrentActiveTab(tab.name);
  }

  
    useEffect(() => {
      //fetchTicketsAndStats(startDate, endDate);

        getAllTickets(startDate, endDate);
      
    }, []);

  const getAllTickets = async (startDate, endDate) => {
    setLoadingTickets(true);

     console.log("Get all tickets");
     console.log(startDate);
        console.log(endDate);

        var result = await getAllTicketsForDateRange(startDate, endDate, appContext.handleAPICallError);

        console.log(result);

        if(result){
            setTickets(result);



            const categories = ['Client Technical Support', 'Employee Technical Support', 'Client Transportation Request', 'Other Client Request', 'Client Consultation Request'];
            const newStats: TicketStats[] = categories.map(category => {
            var categoryTickets = result.filter(ticket => ticket.ticketCategory === category);
            if (category === 'Other Client Request') {
                categoryTickets = result.filter(ticket => ticket.ticketCategory === 'Other Client Request' || !categories.includes(ticket.ticketCategory));
            }
           
            return {
                name: category,
                pendingLow: categoryTickets.filter(t => t.status === 'Pending' && t.ticketSeverity === 'Low').length,
                pendingModerate: categoryTickets.filter(t => t.status === 'Pending' && t.ticketSeverity === 'Moderate').length,
                pendingHigh: categoryTickets.filter(t => t.status === 'Pending' && t.ticketSeverity === 'High').length,
                resolvedLow: categoryTickets.filter(t => t.status === 'Resolved' && t.ticketSeverity === 'Low').length,
                resolvedModerate: categoryTickets.filter(t => t.status === 'Resolved' && t.ticketSeverity === 'Moderate').length,
                resolvedHigh: categoryTickets.filter(t => t.status === 'Resolved' && t.ticketSeverity === 'High').length,
            };
            });

            setStats(newStats);
        }

    setLoadingTickets(false);

    }

  useEffect(() => {
      const categories = ['Client Technical Support', 'Employee Technical Support', 'Client Transportation Request', 'Other Client Request', 'Client Consultation Request'];
      const newStats: TicketStats[] = categories.map(category => {
      var categoryTickets = tickets.filter(ticket => ticket.ticketCategory === category);
      if (category === 'Other Client Request') {
          categoryTickets = tickets.filter(ticket => ticket.ticketCategory === 'Other Client Request' || !categories.includes(ticket.ticketCategory));
      }
    
      return {
          name: category,
          pendingLow: categoryTickets.filter(t => t.status === 'Pending' && t.ticketSeverity === 'Low').length,
          pendingModerate: categoryTickets.filter(t => t.status === 'Pending' && t.ticketSeverity === 'Moderate').length,
          pendingHigh: categoryTickets.filter(t => t.status === 'Pending' && t.ticketSeverity === 'High').length,
          resolvedLow: categoryTickets.filter(t => t.status === 'Resolved' && t.ticketSeverity === 'Low').length,
          resolvedModerate: categoryTickets.filter(t => t.status === 'Resolved' && t.ticketSeverity === 'Moderate').length,
          resolvedHigh: categoryTickets.filter(t => t.status === 'Resolved' && t.ticketSeverity === 'High').length,
      };
      });

      setStats(newStats);
  }, [tickets]);

  const fetchTicketsAndStats = (start, end) => {
    // This is where you would make an API call to fetch tickets and stats
    // based on the date range. For now, we'll just filter the sample data.
    // const filteredTickets = sampleTickets.filter(ticket => {
    //   const ticketDate = new Date(ticket.createdDate).toISOString().split('T')[0];
    //   return ticketDate >= start && ticketDate <= end;
    // });

    const filteredTickets = sampleTickets;

    //setTickets(filteredTickets);

    // Update stats based on filtered tickets
    // Calculate stats
    const categories = ['Client Technical Support', 'Employee Technical Support', 'Client Transportation Request', 'Other Client Request', 'Client Consultation Request'];
    const newStats: TicketStats[] = categories.map(category => {
      const categoryTickets = filteredTickets.filter(ticket => ticket.category === category);
      return {
        name: category,
        pendingLow: categoryTickets.filter(t => t.status === 'Pending' && t.severity === 'Low').length,
        pendingModerate: categoryTickets.filter(t => t.status === 'Pending' && t.severity === 'Moderate').length,
        pendingHigh: categoryTickets.filter(t => t.status === 'Pending' && t.severity === 'High').length,
        resolvedLow: categoryTickets.filter(t => t.status === 'Resolved' && t.severity === 'Low').length,
        resolvedModerate: categoryTickets.filter(t => t.status === 'Resolved' && t.severity === 'Moderate').length,
        resolvedHigh: categoryTickets.filter(t => t.status === 'Resolved' && t.severity === 'High').length,
      };
    });

    setStats(newStats);

    //setStats(sampleStats);

    // setStats(Object.entries(newStats).map(([name, data]) => ({
    //   name,
    //   pending: data.pending,
    //   resolved: data.resolved
    // })));
  };

  const [actionType, setActionType] = useState(null);
  const [actionComment, setActionComment] = useState<string>('');

  const textareaRef = useRef(null);

  useEffect(() => {
    if (actionType && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [actionType]);

  const handleResolveTicket = (ticketId) => {
    setActionType('resolve');
    setExpandedTicketId(ticketId);
  };

  const handleSendTeamsMessage = (ticketId) => {
    setActionType('teams');
    setExpandedTicketId(ticketId);
  };

  

  const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="tw-bg-white tw-p-2 tw-border tw-border-gray-300 tw-rounded">
          <p className="tw-font-bold">{label}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {entry.name}: {entry.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  

  const TicketDetails = ({ ticket }) => {
    const [newComment, setNewComment] = useState('');

    const [savingComment, setSavingComment] = useState(false);

    const cancelAction = () => {
      setActionType(null);
      setNewComment('');
    };

    const confirmAction = async (ticketId) => {
      setSavingComment(true);

      if (actionType === 'resolve') {
        // setTickets(tickets.map(ticket => 
        //   ticket.ticketID === ticketId ? {...ticket, status: 'Resolved'} : ticket
        // ));
        // In a real scenario, you would also send an API request to update the ticket status
        console.log(`Resolving ticket ${ticketId} with comment: ${actionComment}`);

        // Get the selected ticket
        const ticket = tickets.find(t => t.ticketID === ticketId);

        // Get Current User Profile
        const currentUser = await getGraphProfile();

        // Get Chat
        const chat = {
          chatType: 'oneOnOne',
          members: [
            {
              '@odata.type': '#microsoft.graph.aadUserConversationMember',
              roles: ['owner'],
              'user@odata.bind': 'https://graph.microsoft.com/beta/users(\'' + currentUser.mail + '\')'
            },
            {
              '@odata.type': '#microsoft.graph.aadUserConversationMember',
              roles: ['owner'],
              'user@odata.bind': 'https://graph.microsoft.com/beta/users(\'' + ticket.createdBy + '\')'
            }
          ]
        };

        const createdChat = await createChat(chat);

        var ticketComment : SupportTicketComment = {
          supportTicketCommentID: '',
          ticketID: ticket.ticketID,
          comment: escapeSingleQuotes(newComment),
        }
  
        const result = await addCommentToSupportTicket(ticketComment, ticket.ticketID, appContext.handleAPICallError);

        const resolvedTicket = await markTicketAsResolved(ticketId, appContext.handleAPICallError);

        console.log(resolvedTicket);

        const currentOrigin = window.location.origin;

        const ticketURL = `${currentOrigin}/support/tickets/${ticketId}`;

        // JavaScript object with template literals
        const notification = {
          title: `${currentUser.displayName} has resolved your support ticket`,
          subtitle: `<h3>Ticket Subject: ${truncateString(ticket.ticketSubject)}</h3>`,
          text: `Severity: ${ticket.ticketSeverity} <br>\r\nCreated Date: ${new Date(ticket.createdDate).toLocaleString()} <br>\r\nResolution Message: ${newComment}`,
          buttons: [
            {
              type: "openUrl",
              title: "Support Tickets",
              text: "goToSupportTickets",
              displayText: "Support Tickets",
              value: ticketURL
            }
          ]
        };


        const jsonString = JSON.stringify(notification, null, 2);

        const chatMessage = {  
          "subject": null,  
          "body": {  
              "contentType": "html",  
              "content": "<attachment id=\"uniqueAttachmentId\"></attachment>"  
          },  
          "attachments": [  
              {  
                  "id": "uniqueAttachmentId",  
                  "contentType": "application/vnd.microsoft.card.thumbnail",  
                  "content": jsonString,  
                  "name": null,  
                  "thumbnailUrl": null  
              }  
          ]  
        }

        const sentMessage = await sendMessageInChat(createdChat.id, chatMessage);

        console.log(sentMessage);

        await updateTicketInList(ticketId);

        setShowSuccessDialog(true);
  

        setSavingComment(false);
      } else if (actionType === 'teams') {
        // In a real scenario, you would implement the logic to send a Teams message here
        console.log(`Sending Teams message for ticket ${ticketId} with message: ${actionComment}`);
  
        // Get the selected ticket
        const ticket = tickets.find(t => t.ticketID === ticketId);
  
        // Get Current User Profile
        const currentUser = await getGraphProfile();
  
        // Get Chat 
        const chat = {
          chatType: 'oneOnOne',
          members: [
            {
              '@odata.type': '#microsoft.graph.aadUserConversationMember',
              roles: ['owner'],
              'user@odata.bind': 'https://graph.microsoft.com/beta/users(\'' + currentUser.mail + '\')'
            },
            {
              '@odata.type': '#microsoft.graph.aadUserConversationMember',
              roles: ['owner'],
              'user@odata.bind': 'https://graph.microsoft.com/beta/users(\'' + ticket.createdBy + '\')'
            }
          ]
        };
  
        const createdChat = await createChat(chat);
  
        // const chatMessage = {
        //   body: {
        //      content: currentUser.displayName + ' has sent a message regarding ticket: ' + ticket.ticketSubject + '\n\n' + 'Message: ' + newComment + '\n\n' + 'To respond to this message, please visit the support ticketing dashboard.',
        //   }
        // };

        const currentOrigin = window.location.origin;

        const ticketURL = `${currentOrigin}/support/tickets/${ticketId}`;

        // JavaScript object with template literals
        const notification = {
          title: `${currentUser.displayName} has commented on your support ticket`,
          subtitle: `<h3>Ticket Subject: ${truncateString(ticket.ticketSubject)}</h3>`,
          text: `Severity: ${ticket.ticketSeverity} <br>\r\nCreated Date: ${new Date(ticket.createdDate).toLocaleString()} <br>\r\nTo respond, go to the support ticket page.`,
          buttons: [
            {
              type: "openUrl",
              title: "Support Tickets",
              text: "goToSupportTickets",
              displayText: "Support Tickets",
              value: ticketURL
            }
          ]
        };

        const jsonString = JSON.stringify(notification, null, 2);

        const chatMessage = {  
          "subject": null,  
          "body": {  
              "contentType": "html",  
              "content": "<attachment id=\"uniqueAttachmentId\"></attachment>"  
          },  
          "attachments": [  
              {  
                  "id": "uniqueAttachmentId",  
                  "contentType": "application/vnd.microsoft.card.thumbnail",  
                  "content": jsonString,  
                  "name": null,  
                  "thumbnailUrl": null  
              }  
          ]  
        }

      //   const chatMessage = {
      //     "body": {
      //       "contentType": "html",
      //       "content": "<attachment id=\"74d20c7f34aa4a7fb74e2b30004247c5\"></attachment>"
      //     },
      //     "attachments": [
      //         {
      //             "id": "74d20c7f34aa4a7fb74e2b30004247c5",
      //             "contentType": "application/vnd.microsoft.card.thumbnail",
      //             "content": "{\"buttons\":[{\"type\": \"messageBack\",\"title\": \"Yes\", \"value\": \"Yes\"}, {\"type\": \"messageBack\",\"title\": \"No\", \"value\": \"No\"}]}"
      //         }
      //     ]
      // };
  
        const sentMessage = await sendMessageInChat(createdChat.id, chatMessage);
  
        console.log(sentMessage);
  
        var ticketComment : SupportTicketComment = {
          supportTicketCommentID: '',
          ticketID: ticket.ticketID,
          comment: escapeSingleQuotes(newComment),
        }
  
        const result = await addCommentToSupportTicket(ticketComment, ticket.ticketID, appContext.handleAPICallError);
  
        await updateTicketInList(ticketId);

      }
      setActionType(null);
      setNewComment('');

      setSavingComment(false);
    };

    return <div className="tw-bg-white tw-p-4 tw-rounded-md tw-shadow-md tw-mt-2">
      <h3 className="tw-text-xl tw-font-bold tw-mb-4">{ticket.ticketSubject}</h3>
      <p><strong>Category:</strong> {ticket.ticketCategory}</p>
      <p><strong>Severity:</strong> {ticket.ticketSeverity}</p>
      {ticket.ticketCategory !== "Employee Technical Support" && <p><strong>Client:</strong> {ticket.clientName}</p>}
      <p><strong>Created By:</strong> {ticket.createdBy}</p>
      <p><strong>Created:</strong> {new Date(ticket.createdDate).toLocaleString()}</p>
      <p><strong>Description:</strong> {ticket.ticketDescription}</p>
      <p><strong>Steps Taken:</strong> {ticket.stepsTaken}</p>
      <p><strong>Status:</strong> {ticket.status}</p>
      {actionType ? (
        <div className="tw-mt-4" key={ticket.ticketID}>
          <Textarea id={"action-comment-" + ticket.ticketID} className={`!tw-w-[calc(100%)] ${savingComment ? "tw-bg-gray-200" : ""}`} placeholder={actionType === 'resolve' ? "Enter resolution details..." : "Enter Teams message..."} disabled={savingComment} onChange={ e => {
							// setValues({
							// 	...values,
							// 	"reasonDetail": e.target.value
							// })
              console.log(e.target.value);
              setNewComment(e.target.value);
              //setActionComment(e.target.value);
						}}>
						</Textarea>


            {/* <textarea
                ref={textareaRef}
                
                id={"action-comment-" + ticket.ticketID}
                className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md"
                rows={3}
                defaultValue={actionComment}
                onBlur={handleCommentChange}
                placeholder={actionType === 'resolve' ? "Enter resolution details..." : "Enter Teams message..."}
            ></textarea> */}
          <div className="tw-flex tw-justify-end tw-mt-2 tw-space-x-2">
            {savingComment ? <>
              <LoadingSpinner />

            </>:<>
              <ButtonErrorSecondary onClick={cancelAction}>
              Discard
            </ButtonErrorSecondary>
            <ButtonPrimary onClick={() => confirmAction(ticket.ticketID)} disabled={!newComment.trim()}>
              {actionType === 'resolve' ? "Resolve Ticket" : "Add Comment"}
            </ButtonPrimary>
            </>}
            
          </div>
        </div>
      ) : (
        <div className="tw-flex tw-mt-4 tw-space-x-2">
          {ticket.status !== 'Resolved' &&  <ButtonPrimary onClick={() => handleResolveTicket(ticket.ticketID)}>
            Resolve Ticket
          </ButtonPrimary>}
          <ButtonSecondary onClick={() => handleSendTeamsMessage(ticket.ticketID)}>
            <ChatBubbleLeftEllipsisIcon className="tw-h-5 tw-w-5 tw-mr-2" />
            Add Comment
          </ButtonSecondary>
        </div>
      )}

      <h4 className="tw-mt-4 tw-font-bold">Comments</h4>
      <div className="">
                  { !ticket.comments || ticket.comments.length === 0 && <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-start tw-py-sm tw-mt-4">
                      <span className="tw-text-primary tw-text-5">No Comments Added!</span>
                      </div>}
									{ ticket.comments && ticket.comments?.map( (log, index) => {
										return <CommentBlock key={`comment_${index}`} data={{
											user: {
												name: log?.commenterName,
												image: null
											},
											comment: {
												createdOn: moment.utc(log?.commentDate).tz('America/New_York').format('MMMM DD, YYYY | h:mmA'),
											}
										}}>
										{log?.comment}
									</CommentBlock>
									})}
				</div>
    </div>
  };

  const severityValues = {
    'High': 3,
    'Moderate': 2,
    'Low': 1
  };

  const updateTicketInList = async (ticketID) => {
    const updatedTicket = await getSupportTicketByID(ticketID, appContext.handleAPICallError);

    console.log("Updated Ticket");
    console.log(updatedTicket);

    setTickets(tickets.map(ticket => 
      ticket.ticketID === ticketID ? updatedTicket : ticket
    ));
  }

  const [showPendingTickets, setShowPendingTickets] = useState(true);

  const [showResolvedTickets, setShowResolvedTickets] = useState(true);

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const dismissSuccessDialog = () => {
    setShowSuccessDialog(false);
  }
  
  return (
    <div className="tw-p-6">
      <SuccessDialog  title="Support Ticket Resolved" message="The support ticket has been marked resolved successfully!" onClose={dismissSuccessDialog} closeButtonText="Close" open={showSuccessDialog}/>

      <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Admin Ticketing Dashboard</h1>
      
      <div className={` tw-transition-all tw-duration-300`}>
        <div className="tw-flex  tw-items-center tw-mb-4">
          <div className="tw-flex tw-pt-6  tw-items-center tw-mr-4">
            {/* <span className="tw-mr-2">From:</span> */}
            <CalendarSelector id="startDate" name="Start Date:" value={startDate} onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												setStartDate(e.target.value);
											}}/>
            {/* <input
              type="date"
              value={startDate}
              onChange={e => {
                e.preventDefault();
                setStartDate(e.target.value);
              }}
              className="tw-border tw-border-gray-300 tw-rounded-md tw-px-2 tw-py-1"
            /> */}
          </div>
          <div className="tw-flex tw-pt-6 tw-items-center tw-mr-4">
          <CalendarSelector id="endDate" name="End Date:" value={endDate} onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												setEndDate(e.target.value);
											}}/>
            {/* <span className="tw-mr-2">To:</span>
            <input
              type="date"
              value={endDate}
              onChange={e => {
                e.preventDefault();
                setEndDate(e.target.value);
              }}
              min={startDate}
              className="tw-border tw-border-gray-300 tw-rounded-md tw-px-2 tw-py-1"
            /> */}
          </div>
          <div className="tw-flex tw-flex-col tw-items-center tw-mr-4">
            <Label htmlFor="sortTicketsBy" className="tw-mr-2">Sort By:</Label>
            <Select id="sortTicketsBy" className="tw-pr-8" name="Sort By:" value={sortedBy} onChange={ e => {
                        //console.log("Success! - Select output:", e.target.value)
                        e.preventDefault();
                        console.log(e.target.value);
                        setSortedBy(e.target.value);
                      }
                    }>
                      <option value="createdDate">Date Created</option>
                      <option value="ticketCategory">Category</option>
                    </Select>
          </div>

          <ButtonPrimary className="tw-mt-6 " onClick={(e) => {
            e.preventDefault();
            getAllTickets(startDate, endDate);
          }}>
            Apply Filter
          </ButtonPrimary>
        </div>
        </div>

        {loadingTickets ? <>
            <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md">
                    <LoadingSpinner /> 
					<span className="tw-text-primary tw-text-5 tw-text-center">Loading Support Tickets</span>
                </div>
        </>:<>

        <div className="hidden sm:block">
                    <div className="tw-border-b tw-border-gray-200">
                    <nav className="-tw-mb-px tw-flex tw-space-x-8" aria-label="Tabs">
                        {currentTabs.map((tab) => (
                        <span
                            key={tab.name}
                            className={classNames(
                            tab.current
                                ? 'tw-border-primary tw-text-primary'
                                : 'tw-border-transparent tw-text-gray-500 hover:tw-border-gray-200 hover:tw-text-gray-700 tw-cursor-pointer',
                            'tw-flex tw-whitespace-nowrap tw-border-b-2 tw-py-4 tw-px-1 tw-text-sm tw-font-medium'
                            )}
                            aria-current={tab.current ? 'page' : undefined}
                            onClick={() => NavigateToTab(tab)}
                        >
                            {tab.name}
                            {/* <span
                                className={classNames(
                                tab.current ? 'tw-bg-primary-bg tw-text-primary' : 'tw-bg-gray-100 tw-text-gray-900',
                                'tw-ml-3 tw-hidden tw-rounded-full tw-py-0.5 tw-px-2.5 tw-text-xs tw-font-medium md:tw-inline-block'
                                )}
                            >
                                {tab.count}
                            </span> */}
                        </span>
                        ))}
                    </nav>
                    </div>
                </div>



        {tickets.length === 0 ? <div className="tw-flex tw-flex-col tw-y-space-5px tw-justify-center tw-m-md"> 
            <XCircleIcon className="tw-h-10 tw-w-10 tw-text-red-500 tw-m-auto" />
            <span className="tw-text-primary tw-text-5 tw-text-center">No Support Tickets Found</span>
            </div> : <>

            {currentActiveTab === "List" && <div>
              {tickets.filter(ticket => ticket.status === 'Pending') && <div>
                  <h2 className="tw-text-xl tw-font-semibold tw-my-4 tw-flex tw-justify-between">
                      Pending Tickets
                      <button onClick={() => setShowPendingTickets(!showPendingTickets)} className="tw-ml-2">
                          {showPendingTickets ? <ChevronUpIcon className="tw-h-5 tw-w-5" /> : <ChevronDownIcon className="tw-h-5 tw-w-5" />}
                      </button>
                  </h2>
                {showPendingTickets && <ul className="tw-space-y-2">
                    {sortedTickets?.filter(ticket => ticket.status === 'Pending')
                      //.sort((a, b) => severityValues[b.ticketSeverity] - severityValues[a.ticketSeverity])
                      .map(ticket => (
                      <li key={ticket.ticketID} className="tw-bg-white tw-p-3 tw-rounded-md tw-shadow-sm">
                        <div 
                          className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
                          onClick={() => {
                              if (expandedTicketId === ticket.ticketID && !actionType) {
                                setExpandedTicketId(null);
                              } else {
                                setExpandedTicketId(ticket.ticketID);
                                setActionType(null);
                                setActionComment('');
                              }
                            }}
                        >
                          <span className="tw-font-medium">{ticket.ticketSubject}</span>
                          <div className="tw-flex tw-items-center">
                            <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-medium tw-mr-2 ${
                              ticket.ticketSeverity === 'High' ? 'tw-bg-red-100 tw-text-red-800' :
                              ticket.ticketSeverity === 'Moderate' ? 'tw-bg-yellow-100 tw-text-yellow-800' :
                              'tw-bg-green-100 tw-text-green-800'
                            }`}>
                              {ticket.ticketSeverity}
                            </span>
                            {expandedTicketId === ticket.ticketID ? 
                              <ChevronUpIcon className="tw-h-5 tw-w-5" /> : 
                              <ChevronDownIcon className="tw-h-5 tw-w-5" />
                            }
                          </div>
                        </div>
                        <div className="tw-text-sm tw-text-gray-500">{ticket.ticketCategory} {ticket.clientName ? " - " + ticket.clientName : ""}</div>
                        <div className="tw-text-sm tw-text-gray-500">Created By: {ticket?.createdBy}, Created On: {new Date(ticket.createdDate).toLocaleString()}</div>
                        {expandedTicketId === ticket.ticketID && <TicketDetails ticket={ticket} />}
                      </li>
                    ))}
                  </ul>}
                </div>}

                {tickets.filter(ticket => ticket.status === 'Resolved') && <div className='tw-mt-4'>
                  <h2 className="tw-text-xl tw-font-semibold tw-mb-4 tw-flex tw-justify-between">
                      Resolved Tickets
                      <button onClick={() => setShowResolvedTickets(!showResolvedTickets)} className="tw-ml-2">
                          {showResolvedTickets ? <ChevronUpIcon className="tw-h-5 tw-w-5" /> : <ChevronDownIcon className="tw-h-5 tw-w-5" />}
                      </button>
                  </h2>
                  {showResolvedTickets && <ul className="tw-space-y-2">
                    {sortedTickets?.filter(ticket => ticket.status === 'Resolved')
                      //.sort((a, b) => severityValues[b.ticketSeverity] - severityValues[a.ticketSeverity])
                      .map(ticket => (
                      <li key={ticket.ticketID} className="tw-bg-white tw-p-3 tw-rounded-md tw-shadow-sm">
                        <div 
                          className="tw-flex tw-justify-between tw-items-center tw-cursor-pointer"
                          onClick={() => {
                              if (expandedTicketId === ticket.ticketID && !actionType) {
                                setExpandedTicketId(null);
                              } else {
                                setExpandedTicketId(ticket.ticketID);
                                setActionType(null);
                                setActionComment('');
                              }
                            }}
                        >
                          <span className="tw-font-medium">{ticket.ticketSubject}</span>
                          
                          <div className="tw-flex tw-items-center">
                            <span className={`tw-px-2 tw-py-1 tw-rounded-full tw-text-xs tw-font-medium tw-mr-2 ${
                              ticket.ticketSeverity === 'High' ? 'tw-bg-red-100 tw-text-red-800' :
                              ticket.ticketSeverity === 'Moderate' ? 'tw-bg-yellow-100 tw-text-yellow-800' :
                              'tw-bg-green-100 tw-text-green-800'
                            }`}>
                              {ticket.ticketSeverity}
                            </span>
                            {expandedTicketId === ticket.ticketID ? 
                              <ChevronUpIcon className="tw-h-5 tw-w-5" /> : 
                              <ChevronDownIcon className="tw-h-5 tw-w-5" />
                            }
                          </div>
                        </div>
                        <div className="tw-text-sm tw-text-gray-500">{ticket.ticketCategory} {ticket.clientName ? " - " + ticket.clientName : ""}</div>
                        <div className="tw-text-sm tw-text-gray-500">Created By: {ticket?.createdBy}, Created On: {new Date(ticket.createdDate).toLocaleString()}</div>
                        {expandedTicketId === ticket.ticketID && <TicketDetails ticket={ticket} />}
                      </li>
                    ))}
                  </ul>}
                </div>}
            </div>}

            {currentActiveTab === "Statistics" && <div>

            <h2 className="tw-text-xl tw-font-semibold tw-my-4 tw-flex tw-justify-between">
            Ticket Statistics
            <button onClick={() => setIsStatsCollapsed(!isStatsCollapsed)} className="tw-ml-2">
                {isStatsCollapsed ? <ChevronDownIcon className="tw-h-5 tw-w-5" /> : <ChevronUpIcon className="tw-h-5 tw-w-5" />}
            </button>
            </h2>
            <ResponsiveContainer width="100%" height={400} className={`${isStatsCollapsed ? "tw-hidden": ""}`}>
            {/* <BarChart
                data={stats}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 220, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={200} />
                <Tooltip />
                <Legend />
                <Bar dataKey="pending" fill="#fbbf24" name="Pending" />
                <Bar dataKey="resolved" fill="#34d399" name="Resolved" />
            </BarChart> */}
            <BarChart
                    data={stats}
                    layout="vertical"
                    margin={{ top: 5, right: 30, left: 220, bottom: 5 }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" width={200} />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    <Bar dataKey="pendingLow" stackId="pending" fill="#fef08a" name="Pending Low" />
                    <Bar dataKey="pendingModerate" stackId="pending" fill="#fbbf24" name="Pending Moderate" />
                    <Bar dataKey="pendingHigh" stackId="pending" fill="#f97316" name="Pending High" />
                    <Bar dataKey="resolvedLow" stackId="resolved" fill="#86efac" name="Resolved Low" />
                    <Bar dataKey="resolvedModerate" stackId="resolved" fill="#34d399" name="Resolved Moderate" />
                    <Bar dataKey="resolvedHigh" stackId="resolved" fill="#059669" name="Resolved High" />
                    </BarChart>
            </ResponsiveContainer>
        </div>}

      
      </>}
      </>}
    </div>

  );
};

export default AdminTicketingDashboard;