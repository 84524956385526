import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../Context";
import { useEffect, useLayoutEffect, useState } from "react";
import { getClientByID, getCoverageForSpan } from "../../apicalls/schedulingService";
import { ButtonAnchor, ButtonPrimary } from "../../components/buttons/Buttons";
import { rem } from "../../utilities/helpers";
import { ClientInfoCard } from "../../components/containers/Cards";
import { getAPIToken, parseJwt } from "../../apicalls/billingService";
import moment from "moment";
import CoverageEvent from "../../types/coverageEvent";
import { LoadingItems } from "../TestPage";
import { BreadCumbs, DataBlock, DayBlock, DirectSupportBlock, SupportCoverageBlock, UserBlock } from "../Scheduling/SchedulingUtils";
import { Block, LabelOutputs } from "../../components/utilities/Helpers";
import DirectSupportShiftPanel from "../../components/panels/DirectSupportShiftDetailsPanel";

const DirectSupportWeeklySchedule = props => {
    const navContext = useAppContext();
	const [loading, setLoading] = useState<boolean>(true);
	const { patientID } = useParams();
    const { weekStart, weekEnd } = useParams();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    let navigate = useNavigate();

    const [requirementLoading, setRequirementLoading] = useState(false);
    const [coverageEvents, setCoverageEvents] = useState([]);

    const [weekLoaded, setWeekLoaded] = useState(false);

    const [clientDetails, setClientDetails] = useState(null);

    const [clientServicePlan, setClientServicePlan] = useState(null);

    const [noEntries, setNoEntries] = useState<boolean>(false);

    const [weekDSHours, setWeekDSHours] = useState(0.0);
	const [weekRSHours, setWeekRSHours] = useState(0.0);
    const [currentWeek, setCurrentWeek] = useState([]);

    const DSCodes = ['APC', 'FPC', 'SDC', 'AOC', 'FOC', 'AMW', 'NBC'];

    const diff_hours = (dt2, dt1) => 
	{
		var duration = moment.duration(moment(dt2).diff(moment(dt1)));
            var hours = duration.asHours();
            // console.log(requirement);
            // console.log(hours);
            // console.log(hours < 0);
            if (hours < 0) {
                return hours + 24;
            } else {
                return hours;
            }
	
	}

    useEffect(() => {
        console.log("Week Start");
        console.log(weekStart);
        console.log("Week end");
        console.log(weekEnd);

        const today = moment();

		const fetchClientDetails = async () => {
			const clientDetails = await getClientByID(patientID, navContext.handleAPICallError);

			console.log(clientDetails);

			setClientDetails(clientDetails);
		}

        const fetchCoverage = async (patientID, startTime, endTime) => {

            await fetchClientDetails();

			console.log(startTime);
			console.log(endTime);

			const clientCoverage = await getCoverageForSpan(patientID, startTime, endTime, navContext.handleAPICallError);

			console.log(clientCoverage);

			var dsHours = 0.0;

			moment.suppressDeprecationWarnings = true;

			if(clientCoverage != null){
                setNoEntries(false);
                const dsCoverage = clientCoverage.filter((coverage) => {
                    return DSCodes.includes(coverage.billingCode);
                });

				const sortedCoverage = dsCoverage ? dsCoverage.sort(
					(objA, objB) => {
						var timeA = new Date(objA.startTime);
						var timeB = new Date(objB.endTime);
						return timeA.getTime() - timeB.getTime()
	
					},
				  ): [];
				setCoverageEvents(sortedCoverage);

				sortedCoverage.map((coverage, index) => { 
					if(coverage.status !== "Cancelled"){
						var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                        dsHours += parseFloat(coverageHours.toFixed(2));
						console.log(coverage);
						console.log(coverageHours);
					}
	
				});

                let weekDate = moment(startTime.replace(/-/g, "/"));
				// console.log(weekDate.format("MM-DD-YYYY"));
				// console.log(weekDate);
				// console.log(weekDate <= moment(endTime.replace(/-/g, "/")));
				// console.log(moment(endTime.replace(/-/g, "/")));
                let weekDates = [];
				var weekDirectSupportHours = 0.0;

                while(weekDate <= moment(endTime.replace(/-/g, "/"))) {
                    console.log(weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"));
        
                    const arr: CoverageEvent[] = sortedCoverage.filter((coverage) => {
                        return coverage.displayCoverageDate === weekDate.format("MM-DD-YYYY");
                    });
						

                    var dsHours = 0.0;

                    arr.map((coverage, index) => { 
                        if(coverage.status !== "Cancelled"){
                            var coverageHours = diff_hours(coverage.endTime, coverage.startTime);

                            dsHours += parseFloat(coverageHours.toFixed(2));
							weekDirectSupportHours += parseFloat(coverageHours.toFixed(2));
                        }
        
                    });
        
                    let dateValue = {
                        "dayOfWeek": weekDate.format("dddd").substring(0,3),
                        "date": weekDate.format("DD"),
                        "highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
                        "fullDate": weekDate.format("MM-DD-YYYY"),
                        "shifts": arr ? arr.sort(
                            (objA, objB) => {
                                var timeA = new Date(objA.startTime);
                                var timeB = new Date(objB.endTime);
                                return timeA.getTime() - timeB.getTime()
            
                            },
                          ) : [],
                        "rsHours": 0.0,
                        "dsHours": dsHours,
                    }
        
                    weekDates.push(dateValue);
        
                    weekDate = weekDate.add(1, 'd');
                }


				setWeekDSHours(weekDirectSupportHours);

                console.log(weekDates);

                setCurrentWeek(weekDates);

                setRequirementLoading(false);
			} else {
                setNoEntries(true);

				let weekDate = moment(startTime.replace(/-/g, "/"));
                let weekDates = [];
				var weekDirectSupportHours = 0.0;

				while (weekDate <= moment(endTime.replace(/-/g, "/"))) {
					var dsHours = 0.0;

					let dateValue = {
                        "dayOfWeek": weekDate.format("dddd").substring(0,3),
                        "date": weekDate.format("DD"),
                        "highlight": weekDate.format("MM-DD-YYYY") === today.format("MM-DD-YYYY"),
                        "fullDate": weekDate.format("MM-DD-YYYY"),
                        "shifts": [],
						"notices": [],
                        "rsHours": 0.0,
                        "dsHours": dsHours,
                    }
        
                    weekDates.push(dateValue)
        
                    weekDate = weekDate.add(1, 'd');
				}

				setWeekDSHours(weekDirectSupportHours);

                console.log(weekDates);

                setCurrentWeek(weekDates);

            }

			
            setLoading(false);

			
			
			
			//console.log(weekDates);
		}

        if(weekStart === undefined || weekEnd === undefined){
            setStartDate(moment().startOf('week'));
            setEndDate(moment().startOf('week').add(6, 'd'));

            fetchCoverage(patientID, moment().startOf('week').format("MM-DD-YYYY"), moment().startOf('week').add(6, 'd').format("MM-DD-YYYY"))
			// make sure to catch any error
			.catch(console.error);
        } else {
            setStartDate(moment(weekStart.replace(/-/g, "/")));
            setEndDate(moment(weekEnd.replace(/-/g, "/")));

            fetchCoverage(patientID, moment(weekStart.replace(/-/g, "/")).format("MM-DD-YYYY"), moment(weekEnd.replace(/-/g, "/")).format("MM-DD-YYYY"))
			// make sure to catch any error
			.catch(console.error);
        }

        setWeekLoaded(true);
    }, [patientID])

    const backToClientList = () => {
        navigate("/schedule/direct-support");
    } 

    const scheduleURL = "/schedule/direct-support";

    const nextWeek = () => {
		console.log("Next Week");

		let newStartDate = moment(startDate).add(7, 'd');
		let newEndDate = moment(endDate).add(7, 'd');

		navigate("/legacy/schedule/direct-support/" + patientID + "/weekly-schedule/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));

		// let newStartDate = moment(startDate).add(7, 'd');

		// setStartDate(newStartDate);
	}

	const prevWeek = () => {
		console.log("Prev Week");

		let newStartDate = moment(startDate).add(-7, 'd');
		let newEndDate = moment(endDate).add(-7, 'd');

		navigate("/legacy/schedule/direct-support/" + patientID + "/weekly-schedule/" + newStartDate.format("MM-DD-YYYY") + "/" + newEndDate.format("MM-DD-YYYY"));

		// let newStartDate = moment(startDate).add(-7, 'd');

		// setStartDate(newStartDate);
	}
    
    return (<>
        {loading ? <>
		<LoadingItems />
	</> : <>
        <div className="tw-w-full tw-px-40px">
        <div className="tw-h-vh tw-flex tw-flex-col tw-flex-grow tw-w-full">
				<div className="tw-space-y-20px tw-py-40px ">
					<BreadCumbs name={{
						firstName: clientDetails?.firstName,
						lastName: clientDetails?.lastName
					}}
					started={clientServicePlan?.displayStartDate}
					ended={clientServicePlan?.displayEndDate}
					url={`#`}
					changes={ null }
                    scheduleName={"Direct Support Schedule"}
                    scheduleURL={scheduleURL}
					/>
				</div>
					<div className="tw-flex tw-flex-col tw-flex-grow tw-w-full">
						<div className="tw-flex tw-space-x-40px tw-pb-20px">
							<div className="tw-flex-grow tw-basis-1/3">
								<UserBlock name={{
									firstName: clientDetails?.firstName,
									lastName: clientDetails?.lastName
								}}
								simple
								started={clientServicePlan?.displayStartDate}
								ended={clientServicePlan?.displayEndDate} 
                                bottomLabel={"Go To Legacy Portal"}
                                />
							</div>
							<div className="tw-flex tw-flex-grow tw-basis-1/3">
								<SupportCoverageBlock simple className="tw-w-full tw-flex tw-flex-col" type="Remote Support" weekHours={weekRSHours} month={moment(startDate).format("MMMM YYYY")} week={moment(startDate).format("MM/DD") + " - " + moment(endDate).format("MM/DD YYYY")}/>
							</div>
							<div className="tw-flex tw-flex-grow tw-basis-1/3">
								<SupportCoverageBlock simple className="tw-w-full tw-flex-col" type="Direct Support" weekHours={weekDSHours} month={moment(startDate).format("MMMM YYYY")} week={moment(startDate).format("MM/DD") + " - " + moment(endDate).format("MM/DD YYYY")}/>
							</div>
						</div>
                        <div className="tw-flex tw-items-center tw-py-10px">
							<span className="tw-mr-auto tw-text-dark hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px tw-cursor-pointer" onClick={prevWeek}>
								<LabelOutputs className="tw-text-in" icon="/images/icon-nav-arrow-left.svg" size="10">
									<span className="tw-text-5">
										Previous Week
									</span>
								</LabelOutputs>
							</span>

							<span className="tw-mx-auto tw-font-primary tw-font-primary-bold tw-inline-flex tw-items-center">
								{moment(startDate).format("MMMM YYYY")}
							</span>

							<span className="tw-ml-auto tw-text-dark hover:tw-text-primary tw-inline-flex tw-items-center tw-min-h-30px tw-cursor-pointer" onClick={nextWeek}>
								<LabelOutputs className="tw-text-in" icon="/images/icon-nav-arrow-right.svg" reverse size="10">
									<span className="tw-text-5">
										Next Week
									</span>
								</LabelOutputs>
							</span>
						</div>

                        { noEntries ? <>
							<div className="tw-w-full tw-flex-grow tw-flex tw-flex-col">
								<div className="tw-flex-shrhink-0 tw-grid tw-grid-cols-7 tw-space-x-20px tw-w-full tw-overflow-hidden">
								{ currentWeek.map((item, index) => {
					            return <>
                                    {index === 0 ? <DayBlock changeRequirements className="tw-w-full" date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} rsHours={item.rsHours} dsHours={item.dsHours}>

                                    </DayBlock>:
                                    <DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} rsHours={item.rsHours} dsHours={item.dsHours}>
                                       
                                    </DayBlock>}
                                    
                                </>})}
								</div>

								<div className="tw-flex-grow tw-w-full tw-flex tw-justify-center tw-items-center tw-py-20px">
									<div role="button" className={`tw-text-4 tw-rounded-full tw-w-300px tw-h-300px tw-aspect-square tw-inline-flex tw-space-y-5px tw-py-10px tw-px-5px tw-flex-col tw-justify-center tw-items-center tw-cursor-pointer  tw-bg-[#E5E5E5] tw-text-dark`} onClick={ e => {
											e.preventDefault();
										}}>
										<span className="tw-w-1/4 tw-mx-auto tw-aspect-square">
											<img className="tw-w-full" src="/images/icon-date.svg" />
										</span>
										<b className="tw-leading-none tw-block tw-text-center">
											No Direct Support Scheduled
										</b>
										<span className="tw-text-6 tw-w-full tw-text-center tw-leading-tight">
											Use the &quot;Legacy Portal&quot;  <Block>to add new requirements</Block>
										</span>
									</div>
								</div>
							</div>

						</> : <>
							<div className="tw-flex-grow tw-grid tw-grid-cols-7 tw-space-x-20px tw-w-full tw-overflow-hidden">
							
                            { currentWeek && currentWeek.map((item, index) => {
					            return <>
                                    {index === 0 ? <DayBlock changeRequirements className="tw-w-full" date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} rsHours={item.rsHours} dsHours={item.dsHours}>
		
										{item.shifts && item.shifts !== undefined ? <>
                                            {item.shifts?.map((shift, index) => {
                                                return <DirectSupportBlock key={index} coverageAmount={shift.status} type={shift.shiftType} date={moment(item.fullDate).format("MM/DD/YYYY")} time={`${shift.displayStartTime} - ${shift.displayEndTime}`} onClick={ e => {
													navContext.setSideNavTheme(3);
                                                    navContext.setSideNavTitle("Direct Support Shift Details");
													navContext.setPanelSize('tw-max-w-400px');
													navContext.setSideNav( <DirectSupportShiftPanel date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={null}/> );
													navContext.setSideNavActions(null);
													navContext.setSideNavBottom(null);
													navContext.openSideNav();
												}}></DirectSupportBlock>
                                            })}
                                            </>:<>
                                            
                                        </>}
                                    </DayBlock>:
                                    <DayBlock changeRequirements className="tw-basis-[calc((1/7)*100%)] tw-flex-grow" date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} rsHours={item.rsHours} dsHours={item.dsHours}>
										
										{item.shifts && item.shifts.length !== 0 ? <>
                                            {item.shifts.map((shift, index) => {
                                                return <DirectSupportBlock key={index} coverageAmount={shift.status} type={shift.shiftType} date={moment(item.fullDate).format("MM/DD/YYYY")} time={`${shift.displayStartTime} - ${shift.displayEndTime}`} onClick={ e => {
													navContext.setSideNavTheme(3);
                                                    navContext.setSideNavTitle("Direct Support Shift Details");
													navContext.setPanelSize('tw-max-w-400px');
													navContext.setSideNav( <DirectSupportShiftPanel date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} shift={shift} serviceTypes={null}/> );
													navContext.setSideNavActions(null);
													navContext.setSideNavBottom(null);
													navContext.openSideNav();
												}}></DirectSupportBlock>
                                            })}
                                            </>:<>
                                        </>}

										{/* {((item.shifts.length === 0)) ? <>
											<DataBlock none date={moment(item.fullDate.replace(/-/g, "/")).format("MM/DD/YYYY")} serviceTypes={ null} requirement="on-demand"/>
										</>:<></>} */}
                                    </DayBlock>}
                                    
                                </>})}
							</div>
						</> }
					</div>
			</div>
        </div>
    </>}
    </>);
}

export default DirectSupportWeeklySchedule;