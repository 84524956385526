import { useEffect, useRef, useState } from "react";
import CalendarSelector from "../../components/calendars/CalendarSelector";
import { Block } from "@medforall/medforall-component-library";
import { Select } from "../../components/forms/formFields";
import { ButtonPrimary, ButtonSecondary } from "../../components/buttons/Buttons";
import ProviderCard from "../../components/schedulingCompenents/ProviderCard";
import DetailedProviderCard from "../../components/schedulingCompenents/DetailedProviderCard";
import moment from "moment";
import { useAppContext } from "../../Context";
import { getProviderRecommendations } from "../../apicalls/availabilityService";
import { ProviderRecommendation } from "../../types/providerRecomendation";
import { set } from "lodash";
import { CircularProgress } from "@mui/material";

// Example provider data
// const availableProviders = [
//     {
//       name: 'Alice Johnson',
//       availability: 'available',
//       profilePictureUrl: 'https://unsplash.com/photos/7YVZYZeITc8/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8MjN8fHByb2ZpbGV8ZW58MHx8fHwxNzAxMjAwOTk4fDA&force=true&w=640',
//       hoursAvailable: '9 AM - 5 PM',
//       scheduledHours: '0',
//       timeOffRequests: '0',
//     },
//     {
//       name: 'Bob Smith',
//       availability: 'available',
//       profilePictureUrl: null,
//       hoursAvailable: '9 AM - 5 PM',
//       scheduledHours: '0',
//       timeOffRequests: '0',
//     },
//     {
//       name: 'Charlie Brown',
//       availability: 'available',
//       profilePictureUrl: null,
//       hoursAvailable: '9 AM - 5 PM',
//       scheduledHours: '2',
//       timeOffRequests: '0',
//     },
//     {
//       name: 'David Johnson',
//       availability: 'available',
//       profilePictureUrl: null,
//       hoursAvailable: '9 AM - 5 PM',
//       scheduledHours: '4',
//       timeOffRequests: '0',
//     }
// ];

const providers = [
    {
      name: 'John Doe',
      availability: 'available',
      profilePictureUrl: 'https://unsplash.com/photos/GH-mSApoKO0/download?force=true&w=640', // No image URL provided to show initials
      timeOffRequests: [
        'Vacation - 2023-07-10',
        'Doctor Appointment - 2023-07-15'
      ],
      availableHoursServiceDate: '9 AM - 5 PM',
      scheduledHoursServiceDate: '8 hours',
      availableHoursPreviousDate: '10 AM - 6 PM',
      scheduledHoursPreviousDate: '7 hours',
      availableHoursUpcomingDate: '8 AM - 4 PM',
      scheduledHoursUpcomingDate: '8 hours'
    },
];

// const possibleAvailableProviders = [
//     {
//       name: 'Eve Johnson',
//       availability: 'possiblyAvailable',
//       profilePictureUrl: 'https://oahportalstorageaccount.blob.core.windows.net/profilephotos/wallace.29@ohioathome.com.jpg',
//       hoursAvailable: 'N/A',
//       scheduledHours: '4',
//       timeOffRequests: '1',
//     },
//     {
//       name: 'Frank Smith',
//       availability: 'possiblyAvailable',
//       profilePictureUrl: null,
//       hoursAvailable: 'N/A',
//       scheduledHours: '3',
//       timeOffRequests: '1',
//     },
//     {
//       name: 'George Brown',
//       availability: 'possiblyAvailable',
//       profilePictureUrl: null,
//       hoursAvailable: 'N/A',
//       scheduledHours: '4',
//       timeOffRequests: '0',
//     },
//     {
//       name: 'Helen Johnson',
//       availability: 'possiblyAvailable',
//       profilePictureUrl: null,
//       hoursAvailable: 'N/A',
//       scheduledHours: '4',
//       timeOffRequests: '1',
//     }
// ];

// const notAvailableProviders = [
//     {
//       name: 'Ivan Johnson',
//       availability: 'notAvailable',
//       profilePictureUrl: null,
//       hoursAvailable: 'N/A',
//       scheduledHours: '4',
//       timeOffRequests: '2',
//     },
//     {
//       name: 'John Smith',
//       availability: 'notAvailable',
//       profilePictureUrl: 'https://unsplash.com/photos/7uoMmzPd2JA/download?force=true&w=640',
//       hoursAvailable: 'N/A',
//       scheduledHours: '3',
//       timeOffRequests: '1',
//     },
//     {
//       name: 'Kevin Brown',
//       availability: 'notAvailable',
//       profilePictureUrl: null,
//       hoursAvailable: '9 AM - 5 PM',
//       scheduledHours: '8',
//       timeOffRequests: '0',
//     },
//     {
//       name: 'Linda Johnson',
//       availability: 'notAvailable',
//       profilePictureUrl: null,
//       hoursAvailable: '8 AM - 4 PM',
//       scheduledHours: '8',
//       timeOffRequests: '0',
//     }
// ];


const SchedulingCopilotPage = props => {
    const [supportType, setSupportType] = useState('');
    const [dateOfService, setDateOfService] = useState(moment().format('MM-DD-YYYY'));

    const [showAll, setShowAll] = useState(true);

    const [showAvailable, setShowAvailable] = useState(false);
    const [showPossiblyAvailable, setShowPossiblyAvailable] = useState(false);
    const [showNotAvailable, setShowNotAvailable] = useState(false);

    const [allProviders, setAllProviders] = useState<ProviderRecommendation[]>([]);

    const [availableProviders, setAvailableProviders] = useState<ProviderRecommendation[]>([]);
    const [possibleAvailableProviders, setPossibleAvailableProviders] = useState<ProviderRecommendation[]>([]);
    const [notAvailableProviders, setNotAvailableProviders] = useState<ProviderRecommendation[]>([]);

    const [recommendationsAvailable, setRecommendationsAvailable] = useState(false);

    const [loading, setLoading] = useState(false);

    const navContext = useAppContext();

    const updateServiceDate = (date: string) => {
      console.log("Updating Start Date");
      console.log(date);
      setDateOfService(date);
    }
  
    const handleSupportTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSupportType(event.target.value);
    };
  
    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setDateOfService(event.target.value);
    };
  
    const handleGenerate = async () => {
      setRecommendationsAvailable(false);

      console.log('Generating schedule for', supportType, 'on', dateOfService);
      // Implement schedule generation logic here
      setLoading(true);
      const recommendations = await getProviderRecommendations(supportType, dateOfService, navContext.handleAPICallError);

      recommendations.forEach(recommendation => {
        recommendation.showDetails = false;

        if (recommendation.timeOffRequests && recommendation.timeOffRequests.length > 0) {
          var timeOffString = "";

          recommendation.timeOffRequests.forEach(request => {
            timeOffString += request.reason + " - " + moment.utc(request.startDate).format('MM/DD/YYYY') + " - " + moment.utc(request.endDate).format('MM/DD/YYYY') + "; ";
          });

          if (timeOffString.length == 0) {
            timeOffString = "None"
          }

          recommendation.timeOffRequestSummary = timeOffString;
        } else {
          recommendation.timeOffRequestSummary = "None";
        }
      }
      );

      console.log(recommendations);

      const sortedRecommendations = recommendations.sort((a, b) => {
        if (a.providerName < b.providerName) {
          return -1;
          } else if (a.providerName > b.providerName) {
            return 1;
            } else {
              return 0;
              }
              }
              );

      setAllProviders(sortedRecommendations);

      setLoading(false);

      setRecommendationsAvailable(true);

    };

    useEffect(() => {
      console.log("All Providers");
      console.log(allProviders);
      console.log(allProviders.length);

      setAvailableProviders(allProviders.filter(provider => provider.status === "Available"));

      setPossibleAvailableProviders(allProviders.filter(provider => provider.status === "Possibly Available"));

      setNotAvailableProviders(allProviders.filter(provider => provider.status === "Not Available"));

      // allProviders.forEach(provider => {
      //   if (provider.status === "Available") {
      //     console.log(provider.providerName + " is available");

      //     setAvailableProviders([...availableProviders, provider]);
      //   }
      // });

      // const available = [...allProviders].filter(provider => provider.serviceDateAvailability === "Available");

      // setAvailableProviders(available);

      // const possiblyAvailable = [...allProviders].filter(provider => provider.serviceDateAvailability === "Possibly Available");

      // setPossibleAvailableProviders(possiblyAvailable);

      // const notAvailable = [...allProviders].filter(provider => provider.serviceDateAvailability === "Not Available");

      // setNotAvailableProviders(notAvailable);
    }, [allProviders])

    const showProviderDetails = (providerID: string) => {
      console.log("Show details for provider", providerID);

      const updatedProviders = [...allProviders];

      const providerIndex = updatedProviders.findIndex(provider => provider.providerID === providerID);

      updatedProviders[providerIndex].showDetails = true;

      setAllProviders(updatedProviders);
    }

    const hideProviderDetails = (providerID: string) => {
      console.log("Hide details for provider", providerID);

      const updatedProviders = [...allProviders];

      const providerIndex = updatedProviders.findIndex(provider => provider.providerID === providerID);

      updatedProviders[providerIndex].showDetails = false;

      setAllProviders(updatedProviders);
    }
  
    const handleClear = () => {
      setSupportType('');
      setDateOfService('');
      serviceTypeRef.current.value = '';
      setAllProviders([]);
      setAvailableProviders([]);
      setPossibleAvailableProviders([]);
      setNotAvailableProviders([]);
      setRecommendationsAvailable(false);
      setLoading(false);
    };

    const handleShowAvailable = () => {
        setShowAvailable(true);
        setShowPossiblyAvailable(false);
        setShowNotAvailable(false);
        setShowAll(false);
    }

    const handleShowPossiblyAvailable = () => {
        setShowAvailable(false);
        setShowPossiblyAvailable(true);
        setShowNotAvailable(false);
        setShowAll(false);
    }

    const handleShowNotAvailable = () => {
        setShowAvailable(false);
        setShowPossiblyAvailable(false);
        setShowNotAvailable(true);
        setShowAll(false);
    }


    const serviceTypeRef = useRef() || { current: { value: ''}};
    
    return (<>
        <div className="tw-px-xl tw-py-xl">
                <div className="tw-flex tw-items-center tw-flex tw-text-dark tw-pb-sm">
                    <div className="tw-pb-5">
                        <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Scheduling Assistant</h2>
                    </div>
                </div>

               

                <ul className="tw-space-x-4 tw-flex tw-flex-wrap">
                    <li className="tw-flex-grow tw-mt-0.5">
                                <Block>Service Type:</Block>
                                <Select value="Service Type" ref={ serviceTypeRef } onChange={ e => {
                                                // console.log(e, e.target, e.target.value, selectedClaimType)
                                                // //setSelectedPatient(patientRef?.current?.value);
                                                // setSelectedClaimType(claimTypeRef?.current?.value);
                                                setSupportType(serviceTypeRef?.current?.value);
												                        e.preventDefault();
                                            }}>
                                        <option value="">Select Support Type</option>
                                        <option value="DirectSupport">Direct Support</option>
                                        <option value="RemoteSupport">Remote Support</option>
                                                {/* </> } */}
                                        
                                    
                                </Select>
                    </li>

                    <li className="tw-flex-grow">
									<CalendarSelector id="serviceDate" name="Service Date:" onChange={ e => {
												//console.log("Success! - CalendarSelector output:", e.target.value)
												console.log(e.target.value);
												updateServiceDate(e.target.value);
											}}/>
					</li>

                    <li className="tw-mt-2">
                      <ButtonPrimary className="tw-mt-md" onClick={handleGenerate}>
                        Discover
                      </ButtonPrimary>
                    </li>
                    <li className="tw-mt-2">

                            <ButtonSecondary className="tw-mt-md" onClick={handleClear}>
                              Clear
                            </ButtonSecondary>
                    </li>
                            
                </ul>

                {loading && <div className="tw-flex tw-justify-center tw-m-md">
                        <CircularProgress style={{ color: "#12B4B9" }} ></CircularProgress>
                    </div>}

                {recommendationsAvailable && <>
                  <dl className="tw-mt-5 tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow md:tw-grid-cols-3 md:tw-divide-x md:tw-divide-y-0">
                        <div key={"available"} className={`tw-px-4 tw-py-5 sm:tw-p-6 tw-cursor-pointer ${showAvailable ? "tw-bg-green-500": ""}`} onClick={() => handleShowAvailable()}>
                                <dt className={`tw-text-sm tw-font-normal ${showAvailable ? "tw-text-white": "tw-text-green-500"}`}>Available</dt>
                                <dd className="tw-mt-1 tw-flex tw-items-baseline tw-justify-between md:tw-block lg:tw-flex">
                                <div className={`tw-flex tw-items-baseline tw-text-base tw-font-semibold ${showAvailable ? "tw-text-white": "tw-text-green-500"}`}>
                                    {availableProviders?.length}
                                </div>
                                </dd>
                        </div>

                        <div key={"possiblyAvailable"} className={`tw-px-4 tw-py-5 sm:tw-p-6 tw-cursor-pointer ${showPossiblyAvailable ? "tw-bg-yellow-500": ""}`} onClick={() => handleShowPossiblyAvailable()}>
                                <dt className={`tw-text-sm tw-font-normal ${showPossiblyAvailable ? "tw-text-white": "tw-text-yellow-500"}`}>Possibly Available</dt>
                                <dd className="tw-mt-1 tw-flex tw-items-baseline tw-justify-between md:tw-block lg:tw-flex">
                                <div className={`tw-flex tw-items-baseline tw-text-base tw-font-semibold ${showPossiblyAvailable ? "tw-text-white": "tw-text-yellow-500"}`}>
                                    {possibleAvailableProviders?.length}
                                </div>
                                </dd>
                        </div>

                        <div key={"notAvailable"} className={`tw-px-4 tw-py-5 sm:tw-p-6 tw-cursor-pointer ${showNotAvailable ? "tw-bg-red-500": ""}`} onClick={() => handleShowNotAvailable()}>
                                <dt className={`tw-text-sm tw-font-normal ${showNotAvailable ? "tw-text-white": "tw-text-red-500"}`}>Not Available</dt>
                                <dd className="tw-mt-1 tw-flex tw-items-baseline tw-justify-between md:tw-block lg:tw-flex">
                                <div className={`tw-flex tw-items-baseline tw-text-base tw-font-semibold ${showNotAvailable ? "tw-text-white": "tw-text-red-500"}`}>
                                    {notAvailableProviders?.length}
                                </div>
                                </dd>
                        </div>
                </dl>

                {(showAll || showAvailable) && <div className="tw-mt-4">
                  <h2 className="tw-text-green-500 tw-font-semibold tw-text-lg">
                      Available - {availableProviders.length}
                  </h2>

                  <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-m-4">
                      {/* {providers.map(provider => (
                          <DetailedProviderCard 
                          key={provider.name}
                          name={provider.name}
                          availability={provider.availability}
                          profilePictureUrl={provider.profilePictureUrl}
                          timeOffRequests={provider.timeOffRequests}
                          availableHoursServiceDate={provider.availableHoursServiceDate}
                          scheduledHoursServiceDate={provider.scheduledHoursServiceDate}
                          availableHoursPreviousDate={provider.availableHoursPreviousDate}
                          scheduledHoursPreviousDate={provider.scheduledHoursPreviousDate}
                          availableHoursUpcomingDate={provider.availableHoursUpcomingDate}
                          scheduledHoursUpcomingDate={provider.scheduledHoursUpcomingDate}
                          />
                      ))} */}

                      {availableProviders.map(provider => 
                        provider.showDetails ?
                          <DetailedProviderCard 
                          providerID={provider.providerID}
                          key={provider.providerName}
                          name={provider.providerName}
                          providerMail={provider.providerMail}
                          providerPhone={provider.providerPhone}
                          serviceDate={dateOfService}
                          availability={provider.status}
                          profilePictureUrl={provider.profilePhotoAvailable ? provider.profilePhotoURL : null}
                          timeOffRequests={provider.timeOffRequestSummary}
                          availableHoursServiceDate={provider.serviceDateAvailability}
                          scheduledHoursServiceDate={provider.serviceDateSchedule}
                          availableHoursPreviousDate={provider.previousDateAvailability}
                          scheduledHoursPreviousDate={provider.previousDateSchedule}
                          availableHoursUpcomingDate={provider.nextDateAvailability}
                          scheduledHoursUpcomingDate={provider.nextDateSchedule}
                          hideDetails={hideProviderDetails}
                          />
                          :<ProviderCard
                          providerID={provider.providerID}
                          key={provider.providerName}
                          name={provider.providerName}
                          availability={provider.serviceDateAvailability}
                          status={provider.status}
                          profilePictureUrl={provider.profilePhotoAvailable ? provider.profilePhotoURL : null}
                          scheduledHours={provider.serviceDateScheduledHours.toString()}
                          timeOffRequests={provider.timeOffRequests ? provider.timeOffRequests.length.toString() : "0"}
                          showDetails={showProviderDetails}
                          />
                         )}
                  </div>

                </div>}

                {(showAll || showPossiblyAvailable) && <div className={`${showPossiblyAvailable ? "tw-mt-4": ""}`}>
                  <h2 className="tw-text-yellow-500 tw-font-semibold tw-text-lg">
                      Possibly Available - {possibleAvailableProviders.length}
                  </h2>

                  <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-m-4">
                      {possibleAvailableProviders.map(provider => (
                         provider.showDetails ?
                         <DetailedProviderCard 
                         providerID={provider.providerID}
                         key={provider.providerName}
                         name={provider.providerName}
                         providerMail={provider.providerMail}
                         providerPhone={provider.providerPhone}
                         serviceDate={dateOfService}
                         availability={provider.status}
                         profilePictureUrl={provider.profilePhotoAvailable ? provider.profilePhotoURL : null}
                         timeOffRequests={provider.timeOffRequestSummary}
                         availableHoursServiceDate={provider.serviceDateAvailability}
                         scheduledHoursServiceDate={provider.serviceDateSchedule}
                         availableHoursPreviousDate={provider.previousDateAvailability}
                         scheduledHoursPreviousDate={provider.previousDateSchedule}
                         availableHoursUpcomingDate={provider.nextDateAvailability}
                         scheduledHoursUpcomingDate={provider.nextDateSchedule}
                         hideDetails={hideProviderDetails}
                         />
                         :<ProviderCard
                         providerID={provider.providerID}
                         key={provider.providerName}
                         name={provider.providerName}
                         availability={provider.serviceDateAvailability}
                         status={provider.status}
                         profilePictureUrl={provider.profilePhotoAvailable ? provider.profilePhotoURL : null}
                         scheduledHours={provider.serviceDateScheduledHours.toString()}
                         timeOffRequests={provider.timeOffRequests ? provider.timeOffRequests.length.toString() : "0"}
                         showDetails={showProviderDetails}
                         />
                      ))}
                  </div>
                </div>}

                
                {(showAll || showNotAvailable) && <div className={`${showNotAvailable ? "tw-mt-4": ""}`}>
                  <h2 className="tw-text-red-500 tw-font-semibold tw-text-lg">
                      Not Available - {notAvailableProviders.length}
                  </h2>

                  <div className="tw-grid tw-grid-cols-3 tw-gap-4 tw-m-4">
                      {notAvailableProviders.map(provider => (
                          provider.showDetails ?
                          <DetailedProviderCard 
                          providerID={provider.providerID}
                          key={provider.providerName}
                          name={provider.providerName}
                          providerMail={provider.providerMail}
                          providerPhone={provider.providerPhone}
                          serviceDate={dateOfService}
                          availability={provider.status}
                          profilePictureUrl={provider.profilePhotoAvailable ? provider.profilePhotoURL : null}
                          timeOffRequests={provider.timeOffRequestSummary}
                          availableHoursServiceDate={provider.serviceDateAvailability}
                          scheduledHoursServiceDate={provider.serviceDateSchedule}
                          availableHoursPreviousDate={provider.previousDateAvailability}
                          scheduledHoursPreviousDate={provider.previousDateSchedule}
                          availableHoursUpcomingDate={provider.nextDateAvailability}
                          scheduledHoursUpcomingDate={provider.nextDateSchedule}
                          hideDetails={hideProviderDetails}
                          />
                          :<ProviderCard
                          providerID={provider.providerID}
                          key={provider.providerName}
                          name={provider.providerName}
                          availability={provider.serviceDateAvailability}
                          status={provider.status}
                          profilePictureUrl={provider.profilePhotoAvailable ? provider.profilePhotoURL : null}
                          scheduledHours={provider.serviceDateScheduledHours.toString()}
                          timeOffRequests={provider.timeOffRequests ? provider.timeOffRequests.length.toString() : "0"}
                          showDetails={showProviderDetails}
                          />
                      ))}
                  </div>
                </div>}
                </>}

               
                
        </div>
    </>);
}

export default SchedulingCopilotPage;