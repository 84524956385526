import axios, { AxiosResponse } from 'axios';
import { getAPIToken } from './billingService';

// Types
interface PlaylistParams {
  hubId: string;
  from: number;
  to: number;
  cameraId: string;
}

interface PlaylistResponse {
  // Add specific response type properties here based on actual API response
  playlist: string;
}

// Constants
const BASE_URL = 'https://api.dev.medforall.com/hub';

// API Client configuration
const apiClient = axios.create({
  baseURL: BASE_URL,
  timeout: 10000, // 10 seconds
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor for adding auth token
apiClient.interceptors.request.use(
  async (config) => {
    const token = await getAPIToken(); // Implement this based on your auth management
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Playlist Service
export class PlaylistService {
  static async getPlaylist({ hubId, from, to, cameraId }: PlaylistParams): Promise<any> {
    try {
      const response: AxiosResponse<any> = await apiClient.get(
        `/${hubId}/playlist`,
        {
          params: {
            from,
            to,
            cameraId,
          },
        }
      );

      console.log(response);

      console.log('All headers:', Object.keys(response.headers));

      // Extract CloudFront headers
      const { 'CloudFront-Key-Pair-Id': keyPairId, 'CloudFront-Signature': signature, 'CloudFront-Policy': policy } = response.headers;

      return {
        data: response.data,
        headers: {
          keyPairId,
          signature,
          policy,
        },
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle specific error cases
        if (error.response?.status === 401) {
          throw new Error('Unauthorized - Please check your authentication token');
        }
        if (error.response?.status === 404) {
          throw new Error('Playlist not found');
        }
        throw new Error(`Failed to fetch playlist: ${error.message}`);
      }
      throw error;
    }
  }

  // Add more methods as needed for other playlist-related operations
}

// Usage example:
/*
async function fetchPlaylist() {
  try {
    const playlist = await PlaylistService.getPlaylist({
      from: 1733014828,
      to: 1733022028,
      cameraId: 'body'
    });
    return playlist;
  } catch (error) {
    console.error('Error fetching playlist:', error);
    throw error;
  }
}
*/