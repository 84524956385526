import { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../Context";
import moment from "moment";
import { ProviderTimeCalendar, TimeCalendar } from "../../components/containers/Calendars";

const ProviderSessionSchedulePage = props => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { weekStart, weekEnd } = useParams();
    const [weekLoaded, setWeekLoaded] = useState(false);

    const navContext = useAppContext();
    
    useLayoutEffect(() => {
        console.log("Week Start");
        console.log(weekStart);
        console.log("Week end");
        console.log(weekEnd);

        if(weekStart === undefined || weekEnd === undefined){
            setStartDate(moment().startOf('week'));
            setEndDate(moment().startOf('week').add(6, 'd'));
        } else {
            setStartDate(moment(weekStart, "MM-DD-YYYY"));
            setEndDate(moment(weekEnd, "MM-DD-YYYY"));
        }

        setWeekLoaded(true);
        
    },[])

    const parentProps = {
        startDate, endDate,
	}
    
    return (<>
        <div className="tw-px-xl tw-pt-xl">
            <div className="tw-flex tw-items-center tw-flex tw-px-xl tw-text-dark tw-pb-sm">
                            <div className="tw-pb-5">
                                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Session Schedule</h2>
                            </div>
                        
            </div>

            { weekLoaded ? <>
            <div className="tw-px-xl tw-pb-xl tw-text-dark">
                <div className="tw-px-xl">
                    <div>
						<ProviderTimeCalendar { ...parentProps } />
					</div>
                </div>
            </div>
            </>:<></> }
        </div>
    </>);
}

export default ProviderSessionSchedulePage;