import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { Block } from '@medforall/medforall-component-library'
import { Select } from '../../components/forms/formFields'
import { useLayoutEffect, useRef, useState } from 'react'
import Patient from '../../types/patient'
import { ButtonPrimary, ButtonSecondary } from '../../components/buttons/Buttons'
import { useAppContext } from '../../Context'
import { getActiveClients, getActiveDSClients, getDirectSupportMetric } from '../../apicalls/billingService'
import moment from 'moment'
import { LoadingSpinner } from '../../components/portalComponents/loadingSpinner'

const stats = [
  { name: 'Total Subscribers', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
  { name: 'Avg. Open Rate', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
  { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
]

const last30Stats = [
    { name: 'Claimed Amount', stat: '30,000', class: 'tw-text-primary-dark'},
    { name: 'Payroll', stat: '20,000', class: 'tw-text-warning'},
    { name: 'Operating Margin', stat: '10,000', percentage: '33%', operationType: 'profit', showPercentage: true, class: 'tw-text-green-500'},
]

const last90Stats = [
    { name: 'Claimed Amount', stat: '85,000', class: 'tw-text-primary-dark'},
    { name: 'Payroll', stat: '70,000', class: 'tw-text-warning'},
    { name: 'Operating Margin', stat: '15,000', percentage: '17.6%', operationType: 'profit', showPercentage: true, class: 'tw-text-green-500'},
]

const last180Stats = [
    { name: 'Claimed Amount', stat: '160,000', class: 'tw-text-primary-dark'},
    { name: 'Payroll', stat: '140,000', class: 'tw-text-warning'},
    { name: 'Operating Margin', stat: '20,000', percentage: '12.5%', operationType: 'profit', showPercentage: true, class: 'tw-text-green-500'},
]

const lastYearStatsTemplate = [
    { name: 'Claimed Amount', stat: '300,000', class: 'tw-text-primary-dark'},
    { name: 'Payroll', stat: '260,000', class: 'tw-text-warning'},
    { name: 'Operating Margin', stat: '40,000', percentage: '13.3%', operationType: 'profit', showPercentage: true, class: 'tw-text-green-500'},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function approximateToTwoDecimals(num: number): number {

  return Math.round(num * 100) / 100;

}

const MetricsCard = props => {
    return (
      <div>
        <dl className="tw-mt-5 tw-grid tw-grid-cols-1 tw-divide-y tw-divide-gray-200 tw-overflow-hidden tw-rounded-lg tw-bg-white tw-shadow md:tw-grid-cols-3 md:tw-divide-x md:tw-divide-y-0">
          {props?.metrics?.map((item) => (
            <div key={item.name} className="tw-px-4 tw-py-5 sm:tw-p-6">
              <dt className="tw-text-base tw-font-normal tw-text-gray-900">{item.name}</dt>
              <dd className="tw-mt-1 tw-flex tw-items-baseline tw-justify-between md:tw-block lg:tw-flex">
                <div className={`tw-flex tw-items-baseline tw-text-lg tw-font-semibold ${item.class}`}>
                  $ {item.stat}
                </div>
  
                {item.showPercentage && <div
                  className={classNames(
                    item.operationType === 'profit' ? 'tw-bg-green-100 tw-text-green-800' : 'tw-bg-red-100 tw-text-red-800',
                    'tw-inline-flex tw-items-baseline tw-rounded-full tw-px-2.5 tw-py-0.5 tw-text-sm tw-font-medium md:tw-mt-2 lg:tw-mt-0'
                  )}
                >
                  {item.operationType === 'profit' ? (
                    <ArrowUpIcon
                      className="-tw-ml-1 tw-mr-0.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-self-center tw-text-green-500"
                      aria-hidden="true"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="-tw-ml-1 tw-mr-0.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-self-center tw-text-red-500"
                      aria-hidden="true"
                    />
                  )}
  
                  <span className="tw-sr-only"> {item.operationType === 'profit' ? 'Increased' : 'Decreased'} by </span>
                  {item.percentage}
                </div>}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    )
  }

const OperationAnalyticsPage = props => {
    const [activePatients, setActivePatients] = useState<Patient[]>([]);
	  const patientRef = useRef() || { current: { value: ''}};
    const [clientsLoaded, setClientsLoaded] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState('');

    const [last30DayLoading, setLast30DayLoading] = useState(false);
    const [last90DayLoading, setLast90DayLoading] = useState(false);
    const [last180DayLoading, setLast180DayLoading] = useState(false);
    const [lastYearLoading, setLastYearLoading] = useState(false);

    const [last30DayStats, setLast30DayStats] = useState(last30Stats);
    const [last90DayStats, setLast90DayStats] = useState(last90Stats);
    const [last180DayStats, setLast180DayStats] = useState(last180Stats);
    const [lastYearStats, setLastYearStats] = useState(lastYearStatsTemplate);

    const [statsAvailable, setStatsAvailable] = useState(false);

    const appContext = useAppContext();

    useLayoutEffect(() => {
      const fetchClients = async () => {
        const data = await getActiveDSClients(appContext.handleAPICallError);
        console.log(data);
        const activePatients = [...data].sort((a, b) => a.firstName.localeCompare(b.firstName));
        setActivePatients(activePatients);
        setClientsLoaded(true);
      }

      fetchClients()
          .catch(console.error);
    }, []);

    const fetchPatientStats = async () => {
      if(!selectedPatient) return;

      setStatsAvailable(true);
      fetchLast30DayStats();
      fetchLast90DayStats();
      fetchLast180DayStats();
      fetchLastYearStats();
    }

    const clearPatientStats = () => {
      setStatsAvailable(false);
      setSelectedPatient('');
    }

    const fetchLast30DayStats = async () => {
      setLast30DayLoading(true);

      // Get today's date
      const today = moment();

      const endDate = today.subtract(1, 'days').format('YYYY-MM-DD');
      const startDate = today.subtract(31, 'days').format('YYYY-MM-DD');

      const result = await getDirectSupportMetric(selectedPatient, startDate, endDate, appContext.handleAPICallError);

      const operatingMarginPercentage = (result.totalClaim - result.totalPayroll) / result.totalClaim * 100;
      const marginType = operatingMarginPercentage > 0 ? 'profit' : 'loss';
      

      setLast30DayStats([
        { name: 'Claimed Amount', stat: approximateToTwoDecimals(result.totalClaim).toString(), class: 'tw-text-primary-dark'},
        { name: 'Payroll', stat: approximateToTwoDecimals(result.totalPayroll).toString(), class: 'tw-text-warning'},
        { name: 'Operating Margin', stat: approximateToTwoDecimals(result.operatingMargin).toString(), percentage: approximateToTwoDecimals(operatingMarginPercentage).toString() + '%', operationType: marginType, showPercentage: true, class: marginType === "profit" ? 'tw-text-green-500' : 'tw-text-red-500'},
      ]);

      setLast30DayLoading(false);
    }

    const fetchLast90DayStats = async () => {
      setLast90DayLoading(true);

      // Get today's date
      const today = moment();

      const endDate = today.subtract(1, 'days').format('YYYY-MM-DD');
      const startDate = today.subtract(91, 'days').format('YYYY-MM-DD');

      const result = await getDirectSupportMetric(selectedPatient, startDate, endDate, appContext.handleAPICallError);

      const operatingMarginPercentage = (result.totalClaim - result.totalPayroll) / result.totalClaim * 100;
      const marginType = operatingMarginPercentage > 0 ? 'profit' : 'loss';

      setLast90DayStats([
        { name: 'Claimed Amount', stat: approximateToTwoDecimals(result.totalClaim).toString(), class: 'tw-text-primary-dark'},
        { name: 'Payroll', stat: approximateToTwoDecimals(result.totalPayroll).toString(), class: 'tw-text-warning'},
        { name: 'Operating Margin', stat: approximateToTwoDecimals(result.operatingMargin).toString(), percentage: approximateToTwoDecimals(operatingMarginPercentage).toString() + '%', operationType: marginType, showPercentage: true, class: marginType === "profit" ? 'tw-text-green-500' : 'tw-text-red-500'},
      ]);

      setLast90DayLoading(false);
    }

    const fetchLast180DayStats = async () => {
      setLast180DayLoading(true);

      // Get today's date
      const today = moment();

      const endDate = today.subtract(1, 'days').format('YYYY-MM-DD');
      const startDate = today.subtract(181, 'days').format('YYYY-MM-DD');

      const result = await getDirectSupportMetric(selectedPatient, startDate, endDate, appContext.handleAPICallError);

      const operatingMarginPercentage = (result.totalClaim - result.totalPayroll) / result.totalClaim * 100;
      const marginType = operatingMarginPercentage > 0 ? 'profit' : 'loss';

      setLast180DayStats([
        { name: 'Claimed Amount', stat: approximateToTwoDecimals(result.totalClaim).toString(), class: 'tw-text-primary-dark'},
        { name: 'Payroll', stat: approximateToTwoDecimals(result.totalPayroll).toString(), class: 'tw-text-warning'},
        { name: 'Operating Margin', stat: approximateToTwoDecimals(result.operatingMargin).toString(), percentage: approximateToTwoDecimals(operatingMarginPercentage).toString() + '%', operationType: marginType, showPercentage: true, class: marginType === "profit" ? 'tw-text-green-500' : 'tw-text-red-500'},
      ]);

      setLast180DayLoading(false);
    }

    const fetchLastYearStats = async () => {
      setLastYearLoading(true);

      // Get today's date
      const today = moment();

      const endDate = today.subtract(1, 'days').format('YYYY-MM-DD');
      const startDate = today.subtract(1, 'years').format('YYYY-MM-DD');

      const result = await getDirectSupportMetric(selectedPatient, startDate, endDate, appContext.handleAPICallError);

      const operatingMarginPercentage = (result.totalClaim - result.totalPayroll) / result.totalClaim * 100;
      const marginType = operatingMarginPercentage > 0 ? 'profit' : 'loss';

      setLastYearStats([
        { name: 'Claimed Amount', stat: approximateToTwoDecimals(result.totalClaim).toString(), class: 'tw-text-primary-dark'},
        { name: 'Payroll', stat: approximateToTwoDecimals(result.totalPayroll).toString(), class: 'tw-text-warning'},
        { name: 'Operating Margin', stat: approximateToTwoDecimals(result.operatingMargin).toString(), percentage: approximateToTwoDecimals(operatingMarginPercentage).toString() + '%', operationType: marginType, showPercentage: true, class: marginType === "profit" ? 'tw-text-green-500' : 'tw-text-red-500'},
      ]);

      setLastYearLoading(false);
    }
    
    return (<>
    <div className="tw-px-xl tw-pt-xl">
            <div className="tw-pb-5">
                <h2 className="tw-text-lg tw-font-semibold tw-leading-6 tw-text-gray-900">Client Analytics</h2>
            </div>

            <ul className="tw-space-x-4 tw-flex tw-flex-wrap">
            <li className="tw-w-250px">
						<Block>Select Client:</Block>
						<Select value="Selected Patient" ref={ patientRef } onChange={ e => {
										console.log(e, e.target, e.target.value, selectedPatient)
										setSelectedPatient(patientRef?.current?.value);
										// if(patientRef?.current?.value === "All") {
										// 	setSelectedClaimType("New");
										// }
										// e.preventDefault();
									}}>
							{ clientsLoaded ? <>
								<option value="">--Select Client--</option>
								{/* <option value="All">All</option> */}
								{
									activePatients.map(client =>
										<option value={client.patientID}>{client.firstName} {client.lastName}</option>)
								}
							</>:<>
								<option value="">--Loading--</option>
							</>}
							
						</Select>
					</li>
                    <li className="tw-mt-2">
						<ButtonPrimary className="tw-mt-md" onClick={fetchPatientStats}>
							Generate
						</ButtonPrimary>
					</li>
					<li className="tw-mt-2">
									{/* <ButtonWarning className="tw-mt-md" onClick={clearClaimSummary}>
										Clear
									</ButtonWarning> */}

									<ButtonSecondary className="tw-mt-md" onClick={clearPatientStats}>
										Clear
									</ButtonSecondary>
					</li>
            </ul>

            {statsAvailable && <>
            <h3 className="tw-text-base tw-mt-6 tw-font-semibold tw-leading-6 tw-text-gray-900">Last 30 days</h3>

            {last30DayLoading ? <div className="tw-flex tw-justify-center tw-mx-4">
                <LoadingSpinner />
            </div> :  <MetricsCard metrics={last30DayStats}/>}

            <h3 className="tw-text-base tw-mt-6 tw-font-semibold tw-leading-6 tw-text-gray-900">Last 90 days</h3>

            {last90DayLoading ? <div className="tw-flex tw-justify-center tw-mx-4">
                <LoadingSpinner />
            </div> :  <MetricsCard metrics={last90DayStats}/>}

            <h3 className="tw-text-base tw-mt-6 tw-font-semibold tw-leading-6 tw-text-gray-900">Last 6 months</h3>

            {last180DayLoading ? <div className="tw-flex tw-justify-center tw-mx-4">
                <LoadingSpinner />
            </div> :  <MetricsCard metrics={last180DayStats}/>}

            <h3 className="tw-text-base tw-mt-6 tw-font-semibold tw-leading-6 tw-text-gray-900">Last year</h3>

            {lastYearLoading ? <div className="tw-flex tw-justify-center tw-mx-4">
                <LoadingSpinner />
            </div> :  <MetricsCard metrics={lastYearStats}/>}
            </>}
            
    </div>
    
    </>);

}

export default OperationAnalyticsPage;