import React, { useEffect, useState } from 'react';
import { PlaylistService } from '../../apicalls/thermalService';
import HLSPlayer from '../../components/thermal/HLSPlayer';
import { AlertCircle } from 'lucide-react';

const VideoPage = () => {
  const [playlist, setPlaylist] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPlaylist = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        const now = Math.floor(Date.now() / 1000);
        const oneHourAgo = now - 3600;

        const playlistData = await PlaylistService.getPlaylist({
          hubId: 'mfa-hub-Rf6SNG',
          from: oneHourAgo,
          to: now,
          cameraId: 'body'
        });

        console.log(playlistData);

        setPlaylist(playlistData.data);

        
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load playlist');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlaylist();
  }, []);

  return (
    <div className="tw-min-h-screen tw-bg-gray-100 tw-py-8">
      <div className="tw-max-w-4xl tw-mx-auto tw-px-4">
        <h1 className="tw-text-3xl tw-font-bold tw-text-gray-900 tw-mb-8">
          Video Stream
        </h1>

        {isLoading && (
          <div className="tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-lg tw-p-8 tw-mb-4">
            <div className="tw-animate-spin tw-rounded-full tw-h-8 tw-w-8 tw-border-b-2 tw-border-gray-900" />
          </div>
        )}

        {error && (
          <div className="tw-bg-red-50 tw-border tw-border-red-200 tw-rounded-lg tw-p-4 tw-mb-4">
            <div className="tw-flex tw-items-start">
              <AlertCircle className="tw-h-5 tw-w-5 tw-text-red-500 tw-mt-0.5" />
              <div className="tw-ml-3">
                <h3 className="tw-text-sm tw-font-medium tw-text-red-800">
                  Error Loading Playlist
                </h3>
                <p className="tw-mt-1 tw-text-sm tw-text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        {playlist && (
          <div className="tw-bg-white tw-rounded-lg tw-shadow-sm tw-overflow-hidden">
            <HLSPlayer playlistContent={playlist} />
          </div>
        )}
        
        <div className="tw-mt-4 tw-text-sm tw-text-gray-500">
          {playlist ? 'Stream loaded successfully' : 'Waiting for stream...'}
        </div>
      </div>
    </div>
  );
};

export default VideoPage;